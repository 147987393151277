<template>
  <div class="auth-container">
    <div class="auth-header">
      <template v-for="item in menus">
        <div v-if="item.show" :key="item.code" :class="['auth-menu', currentName == item.code ? 'menu-selected' : '']" @click="menuSelected(item.code)">{{item.name}}</div>
      </template>
    </div>
    <div class="auth-content">
      <template v-for="(item, idx) in menus">
        <div :key="'tree'+idx" v-if="item.tree && item.code == currentName && item.show" class="treeGroup">
          <treeGroup :allSimple='true'></treeGroup>
        </div>
        <component v-if="item.code == currentName && item.show" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import weixin from '@/components/platform/weixin'
import statePage from '@/components/transformer/state/index'
import commonRole from '@/components/auth/commonRole/index'
import configPage from '@/components/transformer/config/index'
import treeGroup from '@/pages/treeGroup/Index'
export default {
  name: 'FactorySystemIndex',
  components: {
    treeGroup
  },
  data() {
    return {
      isCollapsed: false,
      currentName: 'b001',
      menus: [
        { code: 'b001', name: '概览', com: weixin, show: true, args: {}, tree: true },
        { code: 'b002', name: '状态监控', com: statePage, show: true, args: {}, tree: true },
        { code: 'b003', name: '配置管理', com: configPage, show: true, args: {}, tree: true },
        { code: 'b004', name: '查询历史', com: commonRole, show: true, args: {}, tree: true },
      ],
      count: 0,
    }
  },
  computed: {
    ...mapState('sys', ['myMenus', 'menusId']),
  },
  mounted: function () {
    // this.menusList()
  },
  methods: {
    // menusList() {
    //   this.myMenus.functions.forEach(el => {
    //     if (this.menusId == el.moduleId) {
    //       this.menus.forEach(ele => {
    //         if (ele.code == el.code) {
    //           ele.show = true;
    //         }
    //       })
    //     }
    //   });
    // },
    menuSelected: function (name) {
      if (this.currentName == name) return;
      this.currentName = name;
    },
  }
}
</script>
<style scoped>
.auth-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.auth-header {
  height: 66px;
  flex: none;
  display: flex;
  align-items: flex-end;
}
.auth-menu {
  height: 42px;
  padding: 0 24px;
  margin: 0 15px 0 0;
  cursor: pointer;
  white-space: nowrap;
  color: #525c76;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 18px;
  line-height: 42px;
  border-radius: 8px 8px 0px 0px;
}
.treeGroup {
  width: 300px !important;
  flex: none;
  border-radius: 6px;
}
.auth-content {
  height: 400px;
  flex: auto;
  padding: 3px;
  /* background-color: white; */
  background: #f5f6fa;
  border-radius: 0 10px 0 0;
  padding: 20px;
  display: flex;
}
.menu-selected {
  color: #3f75ff;
  background: #f5f6fa;
}
</style>
