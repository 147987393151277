<template>
  <Modal v-model="showModal" :mask-closable="false" width="800px">
    <div slot="header">变压器</div>
    <div>
      <Form ref="form" :model="form" :label-width="160">
        <FormItem prop="name" label="名称">
          <Input v-model="form.name" style="width: 200px"></Input>
        </FormItem>
        <FormItem prop="groupId" label="所在分区">
          <Select :placeholder="showLang('save.select')" v-model="form.groupId" style="width: 200px;">
            <Option v-for="(s, idx) in myGroups" :key="idx" :value="s.id">{{s.name}}</Option>
          </Select>
        </FormItem>
        <FormItem prop="smallType" label="监控器类型">
          <Select :placeholder="showLang('save.select')" v-model="form.smallType" style="width: 200px;margin-right: 10px">
            <Option v-for="(dev, didx) in devicesTypes" :key="didx" :value="dev.code">{{dev.name}}</Option>
          </Select>
        </FormItem>
        <FormItem prop="transType" label="通信模块类型">
          <Select :placeholder="showLang('save.select')" v-model="form.transType" style="width: 200px; margin-right: 10px">
            <Option :value="0">无</Option>
            <Option v-for="(s, idx1) in transTypes" :key="idx1" :value="s.code">{{s.name}}</Option>
          </Select>
        </FormItem>
        <FormItem prop="code" label="通信ID">
          <Input v-model="form.code" style="width: 200px"></Input>
        </FormItem>
        <FormItem prop="location" label="安装位置">
          <Input v-model="form.location" style="width: 200px"></Input>
          安装时间
          <DatePicker type="date" v-model="form.buildDate" placeholder="安装时间" style="width: 200px;margin-right: 10px;" />
        </FormItem>
        <FormItem prop="lng" label="经度">
          <Input type="number" v-model="form.lng" placeholder="经度" disabled style="width: 140px; margin: 0 10px;"></Input>
          纬度
          <Input type="number" v-model="form.lat" placeholder="纬度" disabled style="width: 140px; margin: 0 10px;"></Input>
          <Button type="info" style="margin-right: 8px" @click="showMapModal=true">选择位置</Button>
        </FormItem>
      </Form>
      <ModalSelectMapPoint v-model="showMapModal" :custCenter="{ 'area':form.area,'lat':form.lat,'lng': form.lng}" @posSelected="posSelected" />
    </div>
    <div slot="footer">
      <Button type="info" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">确定</Button>
    </div>
  </Modal>
</template>
<script>
import ModalSelectMapPoint from '@/views/common/modals/ModalSelectMapPoint'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalTransformerEdit',
  components: {
    ModalSelectMapPoint,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      showMapModal: false,
      myGroups: [],
      devicesTypes: [],
      transTypes: [],
      indexVal: 0,
      pos: {
        "lat": 0,
        "lng": 0,
        "location": 0,
      },
      form: {
        "id": "",
        "groupId": "", //所在分区
        "name": null, //名称
        "code": null, //通信ID
        "smallType": 0, //监控器类型
        "transType": 0, //通信模块类型
        "location": "-", //安装位置
        "buildDate": "-", //安装时间
        "lat": 0, //位置，纬度
        "lng": 0, //位置,经度
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('group', ['groupTreeSelectedNode']),
    ...mapState('sys', ['myMenus']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.dataAjax();
        this.typeAjax();
        if (this.item.isAdd) {
          this.form = {
            "id": "",
            "groupId": "", //所在分区
            "name": '', //名称
            "code": '', //通信ID
            "smallType": 0, //监控器类型
            "transType": 0, //通信模块类型
            "location": this.myMenus.project.area, //安装位置
            "buildDate": "-", //安装时间
            "lat": this.myMenus.project.lat, //位置，纬度
            "lng": this.myMenus.project.lng, //位置,经度
          }
        } else {
          this.$set(this, 'form', this.item.data);
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },

  },
  mounted: function () {
  },
  methods: {
    posSelected: function (params) {
      this.form.location = params.address;
      this.form.lat = params.lat;
      this.form.lng = params.lng;
    },
    typeAjax: function () {
      this.$axios.post(`api/powerbox/GetDevicesTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'devicesTypes', res.data);
        }
      });
      this.$axios.post(`api/powerbox/GetTransTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'transTypes', res.data);
        }
      });
    },
    dataAjax() {
      this.myGroups = [];
      this.$axios.post(`api/auth/GetMyGroups`, {}).then(res => {
        if (res.code == 0) {
          let arr = res.data.filter(el => el.id == this.groupTreeSelectedNode.id || el.parentId == this.groupTreeSelectedNode.id)
          this.$set(this, 'myGroups', arr);
        }
      });
    },
    ok() {
      this.$axios.post(`api/powerbox/SaveTransformerConfig`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('设置成功');
          this.$emit("saved");
          this.showModal = false;
        }
      });
    },
  }
}
</script>
<style scoped>
</style>
